var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("PrintingPositionManagementTemplate", {
    attrs: {
      title: _vm.$t("title.agency.registerPrintingPosition"),
      images: _vm.images,
      temporaryId: _vm.temporaryId,
      onComplete: _vm.onComplete,
      onCancel: _vm.onCancel,
      cancelButtonText: _vm.$t("button.return"),
      completeButtonText: _vm.$t("button.next"),
      applicationFormDetails: _vm.applicationFormData,
      type: "create"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }