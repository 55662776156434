<template>
  <PrintingPositionManagementTemplate
    :title="$t('title.agency.registerPrintingPosition')"
    :images="images"
    :temporaryId="temporaryId"
    :onComplete="onComplete"
    :onCancel="onCancel"
    :cancelButtonText="$t('button.return')"
    :completeButtonText="$t('button.next')"
    :applicationFormDetails="applicationFormData"
    type="create"
  />
</template>

<script>
import PrintingPositionManagementTemplate from '@/pages/agency/templates/PrintingPositionManagementTemplate';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'RegisterApplicationFormPrintingPosition',

  components: {
    PrintingPositionManagementTemplate,
  },
  data() {
    return {
      images: this.applicationFormImages(),
      temporaryId: 1,
      applicationFormData: this.applicationFormDetails(),
    };
  },
  async mounted() {
    if (!this.applicationFormPdf()) this.$router.push('/add_application_form');
  },
  methods: {
    ...mapGetters('applicationForm', [
      'applicationFormImages',
      'applicationFormPdf',
      'applicationFormDetails',
    ]),
    ...mapActions('applicationForm', ['setApplicationFormPrintLocation']),
    async onComplete(printX, printY, page) {
      this.setApplicationFormPrintLocation({
        printX: printX,
        printY: printY,
        printPage: page,
      });
      this.$router.push('/create_coordinate_management');
    },
    onCancel() {
      this.$router.go(-1);
    },
  },
};
</script>
